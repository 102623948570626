import type { AppContextInterface } from '@aurora/shared-client/components/context/AppContext/AppContext';
import type { FeaturedWidgetInstance } from '@aurora/shared-client/components/context/FeaturedWidgetContext/FeaturedWidgetContext';
import type { ProvisionalTextData } from '@aurora/shared-client/components/context/ProvisionalTextContext/ProvisionalTextContext';
import { ProvisionTextComponentContext } from '@aurora/shared-client/components/context/ProvisionalTextContext/ProvisionalTextContext';
import { FormGroupFieldSeparator } from '@aurora/shared-client/components/form/enums';
import type { FormSchemaDefinition, FormSpec } from '@aurora/shared-client/components/form/types';
import FormBuilder from '@aurora/shared-client/helpers/form/FormBuilder/FormBuilder';
import type { Board } from '@aurora/shared-generated/types/graphql-schema-types';
import type {
  QuiltFragment,
  QuiltWrapperFragment
} from '@aurora/shared-generated/types/graphql-types';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import type { PageDescriptor } from '@aurora/shared-types/pages';
import type { EndUserPages } from '@aurora/shared-types/pages/enums';
import type { I18n } from '@aurora/shared-types/texts';
import { getLog } from '@aurora/shared-utils/log';
import type { FieldValues } from 'react-hook-form';
import type {
  ConfigurationSpec,
  TextOverrideSpec,
  WidgetProps
} from '../../components/common/Widget/types';
import type {
  EditedQuilt,
  EditedQuiltWrapper
} from '../../components/pageeditor/PageBuilder/types';
import type {
  PageEditorBoardContextFragment,
  PageEditorContextFragment,
  PageEditorNodeContextFragment
} from '../../types/graphql-types';

const log = getLog(module);

export interface TextOverrideData {
  /**
   * The field name of the form that maps to the text override
   */
  fieldName: string;
  /**
   * The text key that gets overridden
   */
  textKey: string;

  /**
   * The desired value
   */
  value: string;

  /**
   * The current value
   */
  originalValue: string;
}

/**
 * Returns whether the specified page editor node context is board context or not
 * @param node
 */
export function isBoardContext(
  node: PageEditorNodeContextFragment | PageEditorBoardContextFragment
): node is PageEditorBoardContextFragment {
  return (node as PageEditorNodeContextFragment).nodeType === 'board';
}

/**
 * updates the editor context with the context from aurora context and returns the updated editor context
 * @param editorContext current editor context
 * @param AppContext current aurora context
 */
export function updateEditorContextWithAppContext(
  editorContext: PageEditorContextFragment,
  AppContext: AppContextInterface
): PageEditorContextFragment {
  const { contextNode, contextMessage } = AppContext;
  const updatedEditorContext: PageEditorContextFragment = { messages: [], nodes: [] };
  if (!contextMessage) {
    if (contextNode.nodeType === 'board') {
      const { conversationStyle } = contextNode as Board;
      editorContext.nodes.forEach(node => {
        if (
          isBoardContext(node) &&
          node.conversationStyle === conversationStyle &&
          node.id !== contextNode.id
        ) {
          updatedEditorContext.nodes.push({ ...node, id: contextNode.id });
        } else {
          updatedEditorContext.nodes.push({ ...node });
        }
      });
    } else {
      editorContext.nodes.forEach(node => {
        if (node.nodeType === contextNode.nodeType && node.id !== contextNode.id) {
          updatedEditorContext.nodes.push({ ...node, id: contextNode.id });
        } else {
          updatedEditorContext.nodes.push({ ...node });
        }
      });
    }
  } else {
    updatedEditorContext.nodes = [...editorContext.nodes];
  }

  editorContext.messages.forEach(message => {
    if (contextMessage) {
      const { id, revisionNum } = contextMessage;
      if (contextNode.nodeType === 'board') {
        const { conversationStyle } = contextNode as Board;

        if (
          message.board.conversationStyle === conversationStyle &&
          (message.id !== id || message.revisionNum !== revisionNum)
        ) {
          updatedEditorContext.messages.push({
            ...message,
            id,
            revisionNum,
            board: { id: contextNode.id, conversationStyle }
          });
        } else {
          updatedEditorContext.messages.push({ ...message });
        }
      } else {
        log.error(
          `Context node with id ${contextNode.id} and type ${contextNode.nodeType} does not match for message page with id ${message.id}`
        );
      }
    } else {
      updatedEditorContext.messages.push({ ...message });
    }
  });

  return updatedEditorContext;
}

/**
 * Adds or updates the specified provisional text data to the source collection.
 * @param source source collection that holds the provisional text data
 * @param value the value that needs to be added/updated to the source
 */
export function addTextData(
  source: ProvisionalTextData[],
  value: ProvisionalTextData
): ProvisionalTextData[] {
  const existingComponentIdx = source.findIndex(
    ({ instanceId, id }) => instanceId === value.instanceId && id === value.id
  );
  if (existingComponentIdx !== -1) {
    const adjustedTexts: ProvisionalTextData[] = [...source];
    const existingTexts = adjustedTexts[existingComponentIdx].texts;

    value.texts.forEach(({ textKey, value: textValue, originalValue }) => {
      const existingKeyIdx = existingTexts.findIndex(
        ({ textKey: existingTextKey }) => existingTextKey === textKey
      );
      if (existingKeyIdx !== -1) {
        existingTexts[existingKeyIdx] = { textKey, value: textValue, originalValue };
      } else {
        existingTexts.push({ textKey, value: textValue, originalValue });
      }
    });

    return adjustedTexts;
  }
  return [...source, value];
}

/**
 * Returns the updated quilts in the session after a reset is performed on a given page
 * @param pageId page id for the quilt
 * @param resetQuilt default quilt to reset to
 * @param originalQuilt the current quilt of the page
 * @param currentQuilts the current quilts in the session
 * @param overriddenQuiltIds collection of quilt ids that are currently overridden
 */
export function getUpdatedQuiltsAfterReset(
  pageId: EndUserPages,
  resetQuilt: QuiltFragment,
  originalQuilt: QuiltFragment,
  currentQuilts: EditedQuilt[],
  overriddenQuiltIds: String[]
): EditedQuilt[] {
  const updatedQuilts: EditedQuilt[] = [...currentQuilts];
  const storedSessionQuiltIndex = currentQuilts.findIndex(quiltInSession => {
    return quiltInSession.quilt.id === resetQuilt.id;
  });
  if (storedSessionQuiltIndex !== -1) {
    if (overriddenQuiltIds.includes(resetQuilt.id)) {
      updatedQuilts[storedSessionQuiltIndex] = {
        pageId,
        quilt: resetQuilt,
        deleteOverride: true,
        currentQuilt: originalQuilt,
        editedTexts: []
      };
    } else {
      updatedQuilts.splice(storedSessionQuiltIndex, 1);
    }
  } else {
    if (overriddenQuiltIds.includes(resetQuilt.id)) {
      updatedQuilts.push({
        pageId,
        quilt: resetQuilt,
        deleteOverride: true,
        currentQuilt: originalQuilt,
        editedTexts: []
      });
    }
  }
  return updatedQuilts;
}

/**
 * Returns the featured widget instances for the page, applying changes if an updated instance is provided
 *
 * @param updatedInstance the updated featured widget instance
 * @param existingFeaturedWidgetInstances existing featured widget instances for the page
 */
export function getFeaturedWidgetInstancesForPage(
  updatedInstance: FeaturedWidgetInstance,
  existingFeaturedWidgetInstances: Array<FeaturedWidgetInstance> = []
): Array<FeaturedWidgetInstance> {
  if (!updatedInstance) {
    return existingFeaturedWidgetInstances || [];
  } else {
    const currentInstanceIdx: number = existingFeaturedWidgetInstances.findIndex(
      instance => instance.instanceId === updatedInstance.instanceId
    );
    if (currentInstanceIdx !== -1) {
      existingFeaturedWidgetInstances[currentInstanceIdx] = updatedInstance;
    } else {
      existingFeaturedWidgetInstances.push(updatedInstance);
    }
    return existingFeaturedWidgetInstances;
  }
}

/**
 * updates the currentQuilts with the updatedQuilt. Adds the updated Quilt if it was not in collection before.
 * If the quilt with id updated quilt is in the collection, updates the collection with updated quilt if it is different
 * than the original quilt else removes it.
 * @param currentQuilts current set of quilts
 * @param updatedQuilt updated quilt
 * @param quiltTemplate the quiltTemplate with the original state of the quilt to compare.
 * @param pageId the end user page id.
 * @param templateId template id if the edited quilt is for a template
 * @param text text override for components in the quilt
 * @param featuredWidgetInstance the instance of the featured widget in the quilt
 * @pparam rText text override of removed components in the quilt
 */
export function getUpdatedQuilts(
  currentQuilts: EditedQuilt[],
  updatedQuilt: QuiltFragment,
  quiltTemplate: EditedQuilt,
  pageId: EndUserPages,
  templateId: string,
  text?: ProvisionalTextData,
  featuredWidgetInstance?: FeaturedWidgetInstance,
  rText?: ProvisionalTextData[]
): EditedQuilt[] {
  const updatedQuilts: EditedQuilt[] = [...currentQuilts];

  const storedSessionQuiltIndex = currentQuilts.findIndex(quiltInSession => {
    return quiltInSession.quilt.id === updatedQuilt.id && quiltInSession.templateId == templateId;
  });

  if (storedSessionQuiltIndex !== -1) {
    const quiltInSession: EditedQuilt = updatedQuilts[storedSessionQuiltIndex];
    updatedQuilts[storedSessionQuiltIndex] = {
      ...quiltInSession,
      quilt: updatedQuilt,
      deleteOverride: false,
      currentQuilt: quiltTemplate.currentQuilt,
      editedTexts: text
        ? addTextData(quiltInSession.editedTexts, text)
        : quiltInSession.editedTexts,
      featuredWidgetInstances: getFeaturedWidgetInstancesForPage(
        featuredWidgetInstance,
        quiltInSession.featuredWidgetInstances
      ),
      removedTexts:
        (rText && [...quiltInSession.removedTexts, ...rText]) || quiltInSession.removedTexts
    };
  } else {
    updatedQuilts.push({
      pageId,
      quilt: updatedQuilt,
      templateId: templateId,
      deleteOverride: false,
      currentQuilt: quiltTemplate.currentQuilt,
      editedTexts: text ? [text] : [],
      featuredWidgetInstances: getFeaturedWidgetInstancesForPage(featuredWidgetInstance),
      contextNodeId: quiltTemplate.contextNodeId,
      associatedNodes: quiltTemplate.associatedNodes,
      removedTexts: rText || []
    });
  }

  return updatedQuilts;
}

/**
 * updates the currentQuiltWrappers with the updatedQuilt. Adds the updated QuiltWrapper if it was not in collection before.
 * If the quiltWrapper with id updated quiltWrapper is in the collection, updates the collection with updated quiltWrapper.
 *
 * @param currentQuiltWrappers current set of quilt wrappers
 * @param updatedQuiltWrapper updated quilt wrapper
 * @param quiltWrapperTemplate the quiltWrapperTemplate with the original state of the quilt wrapper to compare
 * @param defaultQuiltWrapperId the default quilt wrapper id
 * @param templateId template id if the edited quilt wrapper is for a template
 * @param text text override for components in the quilt wrapper
 */
export function getUpdatedQuiltWrappers(
  currentQuiltWrappers: EditedQuiltWrapper[],
  updatedQuiltWrapper: QuiltWrapperFragment,
  quiltWrapperTemplate: EditedQuiltWrapper,
  defaultQuiltWrapperId: string,
  templateId?: string,
  text?: ProvisionalTextData
): EditedQuiltWrapper[] {
  const updatedQuiltWrappers: EditedQuiltWrapper[] = [...currentQuiltWrappers];

  const storedSessionQuiltWrapperIndex = currentQuiltWrappers.findIndex(quiltWrapperInSession => {
    return (
      quiltWrapperInSession.defaultQuiltWrapperId === defaultQuiltWrapperId &&
      quiltWrapperInSession.templateId === templateId
    );
  });

  if (storedSessionQuiltWrapperIndex !== -1) {
    const quiltWrapperInSession: EditedQuiltWrapper =
      updatedQuiltWrappers[storedSessionQuiltWrapperIndex];
    updatedQuiltWrappers[storedSessionQuiltWrapperIndex] = {
      ...quiltWrapperInSession,
      quiltWrapper: updatedQuiltWrapper,
      deleteOverride: quiltWrapperTemplate.deleteOverride,
      currentQuiltWrapper: quiltWrapperTemplate.currentQuiltWrapper,
      editedTexts: text
        ? addTextData(quiltWrapperInSession.editedTexts, text)
        : quiltWrapperInSession.editedTexts
    };
  } else {
    updatedQuiltWrappers.push({
      defaultQuiltWrapperId,
      quiltWrapper: updatedQuiltWrapper,
      templateId: templateId,
      deleteOverride: quiltWrapperTemplate.deleteOverride,
      currentQuiltWrapper: quiltWrapperTemplate.currentQuiltWrapper,
      editedTexts: text ? [text] : [],
      ...(quiltWrapperTemplate.associatedLanguages && {
        associatedLanguages: quiltWrapperTemplate.associatedLanguages
      })
    });
  }

  return updatedQuiltWrappers;
}

/**
 * Returns whether the specified page descriptor is board scoped or not
 * @param pageDescriptor page descriptor
 */
export function isPageDescriptorBoardScoped(pageDescriptor: PageDescriptor): boolean {
  const { scope } = pageDescriptor;
  return scope?.type === NodeType.BOARD && scope?.conversationStyle !== undefined;
}

/**
 * Returns form spec from the specified configuration spec
 * @param spec configuration spec
 * @param i18n i18n for the component
 * @param formSchema form schema
 * @param formClassName classname for the form
 */
export function getFormSpec<PropsT extends WidgetProps, FormDataT extends FieldValues>(
  spec: ConfigurationSpec<PropsT, FormDataT>,
  i18n: I18n<unknown, unknown>,
  formSchema: FormSchemaDefinition,
  formClassName?: string
): FormSpec<FormDataT> {
  const { fieldSpecs, customProps, id } = spec;
  const formBuilder: FormBuilder<FormDataT> = new FormBuilder(
    id,
    i18n,
    formSchema,
    {
      formClassName: formClassName,
      formGroupFieldSeparator: FormGroupFieldSeparator.DIVIDER
    },
    customProps
  );
  fieldSpecs.forEach(configuration => {
    log.debug('formBuilder.addField: %O', configuration);
    formBuilder.addField(configuration);
  });

  return formBuilder.build();
}

/**
 * Returns an array of object with currently modified value and current value (original value) of the key specified by textKey
 * for the specified instanceId and textOverrideSpec of the widget
 * @param textOverrideSpec textOverrideSpec for the widget
 * @param i18n the i18n for the widget text
 * @param originalI18n the i18n for getting the current widget text
 * @param instanceId instanceId of the widget
 */
export function getTextOverrideDataForWidget<FormDataT extends FieldValues>(
  textOverrideSpec: TextOverrideSpec<FormDataT>[],
  i18n: I18n<unknown, unknown>,
  originalI18n: I18n<unknown, unknown>,
  instanceId?: string
): TextOverrideData[] {
  const { formatMessage: widgetFormatMessage } = i18n;
  const { formatMessage: originalWidgetFormatMessage, hasMessage: hasOriginalMessage } =
    originalI18n;

  return (
    textOverrideSpec?.map(({ fieldName, textKey }) => {
      if (instanceId) {
        const instanceKey = `${textKey}@instance:${instanceId}`;
        const originalValue = hasOriginalMessage(instanceKey)
          ? originalWidgetFormatMessage(instanceKey)
          : originalWidgetFormatMessage(textKey);
        return {
          fieldName,
          textKey,
          value: widgetFormatMessage(textKey),
          originalValue
        };
      } else {
        return {
          fieldName,
          textKey,
          value: widgetFormatMessage(textKey),
          originalValue: originalWidgetFormatMessage(textKey)
        };
      }
    }) || []
  );
}

/**
 * Returns the text override data for the given form data and text override spec.
 * @param data form data
 * @param textOverrideSpec text override spec for the widget with its current value and original value
 * @param widgetId widget id
 * @param instanceId instance id of the widget. If undefined, one will be autogenerated.
 * @param generateInstanceId generates an instance id for the widget for the text override if specified instance id is
 * falsy.
 * @param name the name of the field to check
 */
export function getProvisionedText<FormDataT extends FieldValues>(
  data: FormDataT,
  textOverrideSpec: TextOverrideData[],
  widgetId: string,
  instanceId: string,
  generateInstanceId = true,
  name: string = null
): ProvisionalTextData {
  const overriddenText: ProvisionalTextData['texts'] = textOverrideSpec
    .filter(({ fieldName, value }) => {
      return data[fieldName] !== value;
    })
    .map(({ fieldName, textKey, originalValue, value }) => {
      // if no name parameter is passed, we update all fields, else, we only update the fieldName === name
      const finalValue = name === null || name === fieldName ? data[fieldName] : value;
      return {
        textKey,
        value: finalValue,
        originalValue
      };
    });

  if (overriddenText.length > 0) {
    const widgetInstanceId = instanceId ?? (generateInstanceId ? `${Date.now()}` : undefined);
    return {
      id: widgetId,
      instanceId: widgetInstanceId,
      texts: overriddenText,
      context: ProvisionTextComponentContext.WIDGET
    };
  }
  return null;
}
